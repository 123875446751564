import React, { useEffect, useState } from 'react';
import './map.scss';

const Mapa = ({
    onClick,
    Color = '',
    closeModal,
    host,
    entradas = null,
    moneda
}) => {
    const [Sections, setSections] = useState([]);
    const [SectionHover, setSectionHover] = useState(null);
    useEffect(() => {
        let blocks = document.querySelectorAll(
            '#gold,#expo,#grada-norte,#grada-sur'
        );
        setSections(blocks);
        const scc = document.querySelector('#scroll-svg');
        console.log(scc.getBoundingClientRect().width, scc.scrollWidth);
        scc.scrollTo(
            (scc.scrollWidth - scc.getBoundingClientRect().width) / 2,
            (scc.scrollHeight - scc.getBoundingClientRect().height) / 2
        );
    }, []);
    useEffect(() => {
        if (Sections.length === 0) return;
        Array.from(Sections).map((s, i) => {
            s.addEventListener('mouseenter', hover);
            s.addEventListener('mouseleave', blur);
            if (entradas[s.id].trim().toLowerCase() === 'agotado') {
                s.style.fill = '#9e9e9e';
                s.style.cursor = 'not-allowed';
            }
            s.addEventListener('click', (e) => click(s.id));
            const childern = s.childNodes;
            if (childern) {
                childern.forEach((ch) => {
                    ch.addEventListener('click', (e) => click(s.id));
                    if (entradas[s.id].trim().toLowerCase() === 'agotado') {
                        ch.style.fill = '#9e9e9e';
                        ch.style.cursor = 'not-allowed';
                    }
                });
            }
        });
        return () => {
            Array.from(Sections).map((s, i) => {
                s.removeEventListener('mouseenter', hover);
                s.removeEventListener('mouseleave', blur);
                s.removeEventListener('click', click);
            });
        };
    }, [Sections]);
    const hover = (e) => {
        Array.from(Sections).map((s, i) => {
            if (e.target.id === s.id) {
                s.classList.add('active');
                setSectionHover({
                    ...e.target.dataset,
                    url: entradas[e.target.id]
                });
            }
        });
    };
    const click = (section) => {
        if (
            entradas[section].trim() !== '' &&
            entradas[section].trim().toLowerCase() !== 'agotado'
        ) {
            window.location.href = `${entradas[section]}/?host=${host}`;
        }
    };
    const blur = (e) => {
        Array.from(Sections).map((s, i) => {
            s.classList.remove('active');
            setSectionHover(null);
        });
    };
    return (
        <section id="modalMap">
            <span className="overlay" onClick={() => closeModal()}></span>
            <section id="modalContent">
                <span className="cerrar" onClick={() => closeModal()}>
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x "
                    >
                        <g>
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </g>
                    </svg>
                </span>
                <h3 className="text-center mb-4">Seleccionar zona</h3>
                <div id="scroll-svg">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1383.86 1141.73"
                        id="mapaC"
                    >
                        <g id="Layer_1" data-name="Layer 1">
                            <path
                                id="grada-sur"
                                className="cls-5"
                                d="m690.12,298.02v302h-2.25l-492-2.11-126.12-274.6c-4.53-9.87-.21-21.54,9.65-26.07,2.62-1.2,5.47-1.82,8.35-1.8l579,2.49h0l23.37.09Z"
                            />
                            <path
                                id="grada-norte"
                                className="cls-5"
                                d="m1312.29,316.81c0,2.38-.55,4.72-1.6,6.86l-135.51,278.39-485.06-2.08v-302l605.44,2.6c9.25.06,16.74,7.31,16.73,16.23Z"
                            />
                            <path
                                className="cls-3"
                                d="m1077.07,818.38l-17.74,37.74-12.64,26.91c-5.8,12.34-18.53,19.89-32.14,19.06l-34.2-2.09-181.21-11.1.14-33.66c74.83.32,142.87.61,195.74.74l12.7-37.94,69.35.34Z"
                            />
                            <path
                                className="cls-3"
                                d="m576.62,854.12l-.14,33.55-179.63,9.45-47.56,2.5c-9.39.49-18.14-4.79-22.08-13.33l-15.44-33.59-17.32-37.68,76.84.33,11.78,37.77c60.55.31,126.68.67,193.55,1Z"
                            />
                            <polygon
                                id="gold"
                                className="cls-8"
                                points="1178.7 602.08 1149.26 664.72 223.58 660.75 194.68 597.86 1178.7 602.08"
                            />
                            <path
                                id="expo"
                                className="cls-7"
                                d="m1149.26,664.72l-72.19,153.66-69.38-.3-12.7,37.94c-52.87-.14-120.91-.4-195.74-.74l.12-28.64-222.59-.95-.16,28.43c-66.91-.35-133.06-.71-193.59-1l-11.78-37.74-76.84-.33-70.79-154.27,925.64,3.94Z"
                            />
                            <polygon
                                className="cls-2"
                                points="799.41 826.64 799.14 888.92 685.48 881.97 576.52 887.69 576.78 825.69 799.41 826.64"
                            />
                            <path
                                className="cls-6"
                                d="m953.33,1098.29v.61c-164.29-.31-385.19-.83-529.81-1.66v-1.22c.44-101,119.39-182.25,265.71-181.62s264.53,82.95,264.1,183.89Z"
                            />
                            <path
                                className="cls-1"
                                d="m1381.52,288.12l-392.11,841.46c-3.43,7.19-10.72,11.73-18.68,11.65l-565-2.43c-7.96.02-15.21-4.59-18.58-11.81L2.27,282.19c-6-13.14,4-27.9,18.76-27.83l1342,5.75c14.77,0,24.59,14.87,18.49,28.01Z"
                            />
                            <g className="cls-10">
                                <path
                                    className="cls-9"
                                    d="m608.44,1032.63v-17.19h12.75v2.91h-9.28v3.81h8.63v2.9h-8.63v4.68h9.61v2.9h-13.08Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m623.58,1027.04l3.38-.33c.2,1.13.62,1.97,1.24,2.5.62.53,1.46.8,2.52.8,1.12,0,1.96-.24,2.53-.71.57-.47.85-1.03.85-1.66,0-.41-.12-.75-.36-1.04-.24-.29-.66-.53-1.25-.75-.41-.14-1.33-.39-2.78-.75-1.86-.46-3.17-1.03-3.92-1.7-1.06-.95-1.58-2.1-1.58-3.46,0-.88.25-1.69.74-2.46s1.21-1.34,2.15-1.74c.93-.4,2.06-.6,3.38-.6,2.16,0,3.78.47,4.87,1.42,1.09.95,1.66,2.21,1.72,3.79l-3.47.15c-.15-.88-.47-1.52-.96-1.91s-1.22-.58-2.2-.58-1.8.21-2.37.62c-.37.27-.55.62-.55,1.07,0,.41.17.75.52,1.04.44.37,1.5.75,3.19,1.15,1.69.4,2.94.81,3.75,1.24s1.44,1.01,1.9,1.75c.46.74.69,1.65.69,2.74,0,.99-.27,1.91-.82,2.77s-1.32,1.5-2.32,1.92c-1,.42-2.25.63-3.74.63-2.17,0-3.84-.5-5.01-1.51s-1.86-2.47-2.09-4.39Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m651.48,1026.31l3.37,1.07c-.52,1.88-1.37,3.27-2.57,4.18s-2.72,1.37-4.57,1.37c-2.28,0-4.16-.78-5.63-2.34-1.47-1.56-2.21-3.69-2.21-6.4,0-2.86.74-5.08,2.22-6.67,1.48-1.58,3.42-2.38,5.83-2.38,2.1,0,3.81.62,5.12,1.87.78.73,1.37,1.79,1.76,3.17l-3.44.82c-.2-.89-.63-1.59-1.27-2.11-.65-.52-1.43-.77-2.35-.77-1.27,0-2.31.46-3.1,1.37s-1.19,2.4-1.19,4.45c0,2.17.39,3.72,1.17,4.64.78.92,1.8,1.38,3.05,1.38.92,0,1.72-.29,2.38-.88.66-.59,1.14-1.51,1.43-2.77Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m657.83,1032.63v-17.19h12.75v2.91h-9.28v3.81h8.63v2.9h-8.63v4.68h9.61v2.9h-13.08Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m673.88,1032.63v-17.19h3.38l7.04,11.48v-11.48h3.23v17.19h-3.48l-6.93-11.21v11.21h-3.23Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m706.7,1032.63h-3.78l-1.5-3.91h-6.87l-1.42,3.91h-3.68l6.7-17.19h3.67l6.88,17.19Zm-6.39-6.8l-2.37-6.38-2.32,6.38h4.69Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m708.55,1032.63v-17.19h7.31c1.84,0,3.17.16,4,.46.83.31,1.5.86,2,1.65.5.79.75,1.69.75,2.71,0,1.29-.38,2.36-1.14,3.2-.76.84-1.89,1.37-3.4,1.59.75.44,1.37.92,1.86,1.44.49.52,1.15,1.45,1.98,2.79l2.1,3.35h-4.15l-2.51-3.74c-.89-1.34-1.5-2.18-1.83-2.53-.33-.35-.68-.59-1.04-.72-.37-.13-.95-.19-1.75-.19h-.7v7.18h-3.47Zm3.47-9.92h2.57c1.67,0,2.71-.07,3.12-.21.41-.14.74-.38.97-.73.24-.34.35-.77.35-1.29,0-.58-.16-1.05-.46-1.4-.31-.36-.74-.58-1.31-.67-.28-.04-1.13-.06-2.53-.06h-2.71v4.36Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m725.78,1032.63v-17.19h3.47v17.19h-3.47Z"
                                />
                                <path
                                    className="cls-9"
                                    d="m731.86,1024.14c0-1.75.26-3.22.79-4.41.39-.88.92-1.66,1.6-2.36.68-.7,1.42-1.21,2.22-1.55,1.07-.45,2.31-.68,3.71-.68,2.53,0,4.56.79,6.08,2.36s2.28,3.76,2.28,6.56-.75,4.95-2.26,6.51c-1.51,1.57-3.53,2.35-6.05,2.35s-4.59-.78-6.1-2.34c-1.51-1.56-2.26-3.71-2.26-6.44Zm3.58-.12c0,1.95.45,3.42,1.35,4.43.9,1,2.04,1.51,3.42,1.51s2.52-.5,3.41-1.5c.89-1,1.33-2.49,1.33-4.49s-.43-3.44-1.3-4.41c-.86-.97-2.01-1.45-3.44-1.45s-2.58.49-3.46,1.47c-.88.98-1.31,2.46-1.31,4.44Z"
                                />
                            </g>
                            <g>
                                <g className="cls-10">
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m344.58,438.5v-4.7h12.14v11.11c-1.18,1.14-2.89,2.15-5.13,3.02-2.24.87-4.51,1.3-6.8,1.3-2.92,0-5.46-.61-7.63-1.84-2.17-1.22-3.8-2.97-4.89-5.25-1.09-2.28-1.64-4.75-1.64-7.43,0-2.9.61-5.49,1.83-7.74,1.22-2.26,3-3.99,5.35-5.19,1.79-.92,4.01-1.39,6.68-1.39,3.46,0,6.17.73,8.11,2.18s3.2,3.46,3.76,6.02l-5.59,1.05c-.39-1.37-1.13-2.45-2.22-3.24-1.08-.79-2.44-1.19-4.06-1.19-2.46,0-4.42.78-5.87,2.34-1.45,1.56-2.18,3.87-2.18,6.94,0,3.31.74,5.79,2.21,7.45,1.47,1.66,3.4,2.48,5.78,2.48,1.18,0,2.36-.23,3.55-.69,1.19-.46,2.2-1.02,3.05-1.68v-3.54h-6.45Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m361.93,448.75v-27.89h11.85c2.98,0,5.15.25,6.5.75s2.43,1.39,3.24,2.67c.81,1.28,1.22,2.75,1.22,4.39,0,2.09-.62,3.82-1.85,5.18-1.23,1.36-3.07,2.22-5.52,2.58,1.22.71,2.22,1.49,3.02,2.34.79.85,1.86,2.36,3.21,4.53l3.41,5.44h-6.73l-4.07-6.07c-1.45-2.17-2.44-3.54-2.97-4.1s-1.1-.95-1.69-1.16c-.6-.21-1.54-.31-2.83-.31h-1.14v11.64h-5.63Zm5.63-16.09h4.17c2.7,0,4.39-.11,5.06-.34.67-.23,1.2-.62,1.58-1.18.38-.56.57-1.26.57-2.09,0-.94-.25-1.7-.75-2.27s-1.21-.94-2.12-1.09c-.46-.06-1.83-.1-4.11-.1h-4.39v7.08Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m415.19,448.75h-6.13l-2.44-6.33h-11.15l-2.3,6.33h-5.97l10.86-27.89h5.95l11.17,27.89Zm-10.37-11.03l-3.84-10.35-3.77,10.35h7.61Z"
                                        />
                                    </g>
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m415.78,420.86h10.29c2.32,0,4.09.18,5.31.53,1.64.48,3.04,1.34,4.2,2.57,1.17,1.23,2.05,2.74,2.66,4.52.61,1.78.91,3.98.91,6.59,0,2.3-.29,4.27-.86,5.94-.7,2.03-1.69,3.67-2.99,4.93-.98.95-2.3,1.69-3.96,2.23-1.24.39-2.9.59-4.98.59h-10.6v-27.89Zm5.63,4.72v18.47h4.2c1.57,0,2.71-.09,3.41-.27.91-.23,1.67-.62,2.27-1.16.6-.54,1.09-1.44,1.47-2.69.38-1.25.57-2.95.57-5.11s-.19-3.81-.57-4.97c-.38-1.15-.91-2.05-1.6-2.7-.69-.65-1.55-1.08-2.61-1.31-.79-.18-2.33-.27-4.62-.27h-2.53Z"
                                        />
                                    </g>
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m470.16,448.75h-6.13l-2.44-6.33h-11.15l-2.3,6.33h-5.97l10.86-27.89h5.95l11.17,27.89Zm-10.37-11.03l-3.84-10.35-3.77,10.35h7.61Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m471.72,439.68l5.48-.53c.33,1.84,1,3.19,2.01,4.05s2.37,1.29,4.08,1.29c1.81,0,3.18-.38,4.1-1.15.92-.77,1.38-1.67,1.38-2.69,0-.66-.19-1.22-.58-1.68-.39-.46-1.06-.87-2.03-1.21-.66-.23-2.16-.63-4.51-1.22-3.02-.75-5.14-1.67-6.35-2.76-1.71-1.53-2.57-3.41-2.57-5.61,0-1.42.4-2.75,1.21-3.99.81-1.24,1.97-2.18,3.48-2.83,1.52-.65,3.34-.97,5.49-.97,3.5,0,6.13.77,7.9,2.3,1.77,1.54,2.7,3.58,2.79,6.14l-5.63.25c-.24-1.43-.76-2.46-1.55-3.09-.79-.63-1.98-.94-3.57-.94s-2.92.34-3.84,1.01c-.6.43-.89,1.01-.89,1.73,0,.66.28,1.22.84,1.69.71.6,2.44,1.22,5.17,1.86,2.74.65,4.77,1.32,6.08,2.01,1.31.69,2.34,1.64,3.08,2.83.74,1.2,1.11,2.68,1.11,4.44,0,1.6-.44,3.09-1.33,4.49-.89,1.4-2.14,2.43-3.77,3.11-1.62.68-3.65,1.02-6.07,1.02-3.53,0-6.23-.81-8.12-2.44-1.89-1.63-3.02-4-3.39-7.12Z"
                                        />
                                    </g>
                                </g>
                                <g className="cls-10">
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m871.83,438.5v-4.7h12.14v11.11c-1.18,1.14-2.89,2.15-5.13,3.02-2.24.87-4.51,1.3-6.8,1.3-2.92,0-5.46-.61-7.63-1.84-2.17-1.22-3.8-2.97-4.89-5.25s-1.64-4.75-1.64-7.43c0-2.9.61-5.49,1.83-7.74,1.22-2.26,3-3.99,5.35-5.19,1.79-.92,4.01-1.39,6.68-1.39,3.46,0,6.17.73,8.11,2.18,1.95,1.45,3.2,3.46,3.76,6.02l-5.59,1.05c-.39-1.37-1.13-2.45-2.22-3.24-1.08-.79-2.44-1.19-4.06-1.19-2.46,0-4.42.78-5.87,2.34-1.45,1.56-2.18,3.87-2.18,6.94,0,3.31.74,5.79,2.21,7.45,1.47,1.66,3.4,2.48,5.78,2.48,1.18,0,2.36-.23,3.55-.69,1.19-.46,2.2-1.02,3.05-1.68v-3.54h-6.45Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m889.18,448.75v-27.89h11.85c2.98,0,5.15.25,6.5.75s2.43,1.39,3.24,2.67,1.22,2.75,1.22,4.39c0,2.09-.62,3.82-1.85,5.18s-3.07,2.22-5.52,2.58c1.22.71,2.22,1.49,3.02,2.34.79.85,1.86,2.36,3.21,4.53l3.41,5.44h-6.73l-4.07-6.07c-1.45-2.17-2.43-3.54-2.97-4.1-.53-.56-1.1-.95-1.69-1.16-.6-.21-1.54-.31-2.83-.31h-1.14v11.64h-5.63Zm5.63-16.09h4.17c2.7,0,4.39-.11,5.06-.34.67-.23,1.2-.62,1.58-1.18.38-.56.57-1.26.57-2.09,0-.94-.25-1.7-.75-2.27s-1.21-.94-2.12-1.09c-.46-.06-1.83-.1-4.11-.1h-4.39v7.08Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m942.44,448.75h-6.12l-2.44-6.33h-11.15l-2.3,6.33h-5.97l10.86-27.89h5.96l11.17,27.89Zm-10.37-11.03l-3.84-10.35-3.77,10.35h7.61Z"
                                        />
                                    </g>
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m943.03,420.86h10.29c2.32,0,4.09.18,5.31.53,1.64.48,3.04,1.34,4.2,2.57,1.17,1.23,2.05,2.74,2.66,4.52.61,1.78.91,3.98.91,6.59,0,2.3-.29,4.27-.86,5.94-.7,2.03-1.69,3.67-2.99,4.93-.98.95-2.29,1.69-3.96,2.23-1.24.39-2.9.59-4.98.59h-10.6v-27.89Zm5.63,4.72v18.47h4.2c1.57,0,2.71-.09,3.41-.27.91-.23,1.67-.62,2.27-1.16.6-.54,1.09-1.44,1.47-2.69s.57-2.95.57-5.11-.19-3.81-.57-4.97c-.38-1.15-.91-2.05-1.6-2.7-.69-.65-1.55-1.08-2.61-1.31-.79-.18-2.33-.27-4.62-.27h-2.53Z"
                                        />
                                    </g>
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m997.41,448.75h-6.12l-2.44-6.33h-11.15l-2.3,6.33h-5.97l10.86-27.89h5.96l11.17,27.89Zm-10.37-11.03l-3.84-10.35-3.77,10.35h7.61Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m998.97,439.68l5.48-.53c.33,1.84,1,3.19,2.01,4.05,1.01.86,2.37,1.29,4.08,1.29,1.81,0,3.18-.38,4.1-1.15.92-.77,1.38-1.67,1.38-2.69,0-.66-.19-1.22-.58-1.68s-1.06-.87-2.03-1.21c-.66-.23-2.16-.63-4.51-1.22-3.02-.75-5.14-1.67-6.35-2.76-1.71-1.53-2.57-3.41-2.57-5.61,0-1.42.4-2.75,1.21-3.99.8-1.24,1.96-2.18,3.48-2.83,1.52-.65,3.35-.97,5.49-.97,3.5,0,6.13.77,7.9,2.3,1.77,1.54,2.7,3.58,2.79,6.14l-5.63.25c-.24-1.43-.76-2.46-1.55-3.09s-1.98-.94-3.57-.94-2.92.34-3.84,1.01c-.6.43-.89,1.01-.89,1.73,0,.66.28,1.22.84,1.69.71.6,2.44,1.22,5.17,1.86,2.74.65,4.76,1.32,6.08,2.01,1.31.69,2.34,1.64,3.08,2.83.74,1.2,1.11,2.68,1.11,4.44,0,1.6-.44,3.09-1.33,4.49-.89,1.4-2.14,2.43-3.77,3.11-1.62.68-3.65,1.02-6.07,1.02-3.53,0-6.23-.81-8.12-2.44-1.89-1.63-3.02-4-3.39-7.12Z"
                                        />
                                    </g>
                                </g>
                                <g className="cls-10">
                                    <path
                                        className="cls-9"
                                        d="m649.05,635.72v-4.7h12.14v11.11c-1.18,1.14-2.89,2.15-5.13,3.02-2.24.87-4.51,1.3-6.8,1.3-2.92,0-5.46-.61-7.63-1.84-2.17-1.22-3.8-2.97-4.89-5.25-1.09-2.28-1.64-4.75-1.64-7.43,0-2.9.61-5.49,1.83-7.74,1.22-2.26,3-3.99,5.35-5.19,1.79-.92,4.01-1.39,6.68-1.39,3.46,0,6.17.73,8.11,2.18s3.2,3.46,3.76,6.02l-5.59,1.05c-.39-1.37-1.13-2.45-2.22-3.24-1.08-.79-2.44-1.19-4.06-1.19-2.46,0-4.42.78-5.87,2.34-1.45,1.56-2.18,3.87-2.18,6.94,0,3.31.74,5.79,2.21,7.45,1.47,1.66,3.4,2.48,5.78,2.48,1.18,0,2.36-.23,3.55-.69,1.19-.46,2.2-1.02,3.05-1.68v-3.54h-6.45Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="m665.24,632.2c0-2.84.42-5.22,1.27-7.15.63-1.42,1.5-2.69,2.6-3.82,1.1-1.13,2.3-1.97,3.6-2.51,1.74-.74,3.74-1.1,6.01-1.1,4.11,0,7.4,1.27,9.86,3.82,2.47,2.55,3.7,6.09,3.7,10.63s-1.22,8.03-3.67,10.57c-2.45,2.54-5.72,3.81-9.82,3.81s-7.44-1.26-9.89-3.79c-2.45-2.53-3.67-6.01-3.67-10.45Zm5.8-.19c0,3.16.73,5.55,2.19,7.18,1.46,1.63,3.31,2.45,5.55,2.45s4.09-.81,5.53-2.43c1.44-1.62,2.16-4.04,2.16-7.28s-.7-5.58-2.1-7.15c-1.4-1.57-3.26-2.36-5.58-2.36s-4.19.8-5.61,2.39c-1.42,1.59-2.13,3.99-2.13,7.2Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="m696.84,645.97v-27.66h5.63v22.96h14v4.7h-19.63Z"
                                    />
                                    <path
                                        className="cls-9"
                                        d="m720.46,618.08h10.29c2.32,0,4.09.18,5.31.53,1.64.48,3.04,1.34,4.2,2.57,1.17,1.23,2.05,2.74,2.66,4.52.61,1.78.91,3.98.91,6.59,0,2.3-.29,4.27-.86,5.94-.7,2.03-1.69,3.67-2.99,4.93-.98.95-2.3,1.69-3.96,2.23-1.24.39-2.9.59-4.98.59h-10.6v-27.89Zm5.63,4.72v18.47h4.2c1.57,0,2.71-.09,3.41-.27.91-.23,1.67-.62,2.27-1.16.6-.54,1.09-1.44,1.47-2.69.38-1.25.57-2.95.57-5.11s-.19-3.81-.57-4.97c-.38-1.15-.91-2.05-1.6-2.7-.68-.65-1.55-1.08-2.61-1.31-.79-.18-2.33-.27-4.62-.27h-2.53Z"
                                    />
                                </g>
                                <g className="cls-10">
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m593.47,761.79v-27.89h20.68v4.72h-15.05v6.18h14v4.7h-14v7.59h15.58v4.7h-21.21Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m616.63,761.79l9.53-14.55-8.64-13.34h6.58l5.59,8.96,5.48-8.96h6.52l-8.67,13.54,9.53,14.34h-6.79l-6.18-9.64-6.2,9.64h-6.75Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m645.45,761.79v-27.89h9.04c3.42,0,5.66.14,6.7.42,1.6.42,2.94,1.33,4.01,2.73,1.08,1.4,1.62,3.21,1.62,5.43,0,1.71-.31,3.15-.93,4.32-.62,1.17-1.41,2.08-2.37,2.75-.96.67-1.93,1.11-2.92,1.32-1.34.27-3.29.4-5.84.4h-3.67v10.52h-5.63Zm5.63-23.17v7.91h3.08c2.22,0,3.7-.15,4.45-.44.75-.29,1.33-.75,1.76-1.37.42-.62.64-1.34.64-2.17,0-1.01-.3-1.85-.89-2.51-.6-.66-1.35-1.07-2.26-1.24-.67-.13-2.02-.19-4.05-.19h-2.72Z"
                                        />
                                    </g>
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m679.26,761.79v-27.89h5.63v12.38l11.38-12.38h7.57l-10.5,10.86,11.07,17.03h-7.29l-7.67-13.09-4.57,4.66v8.43h-5.63Z"
                                        />
                                    </g>
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m731.56,761.79h-6.12l-2.44-6.33h-11.15l-2.3,6.33h-5.97l10.86-27.89h5.96l11.17,27.89Zm-10.37-11.03l-3.84-10.35-3.77,10.35h7.61Z"
                                        />
                                        <path
                                            className="cls-9"
                                            d="m734.61,761.79v-27.89h5.48l11.41,18.62v-18.62h5.23v27.89h-5.65l-11.24-18.19v18.19h-5.23Z"
                                        />
                                    </g>
                                    <g className="cls-10">
                                        <path
                                            className="cls-9"
                                            d="m772.92,761.79v-11.74l-10.22-16.15h6.6l6.56,11.03,6.43-11.03h6.49l-10.25,16.19v11.7h-5.61Z"
                                        />
                                    </g>
                                </g>
                            </g>
                            <line
                                className="cls-4"
                                x1="690.12"
                                y1="298.02"
                                x2="690.12"
                            />
                        </g>
                    </svg>
                </div>
                {SectionHover && false && (
                    <div className="infoSec">
                        {
                            <>
                                {SectionHover.url.trim().toLowerCase() ===
                                    'agotado' && (
                                    <div className="d-flex">
                                        <b className="d-block">AGOTADO</b>
                                    </div>
                                )}
                                <div className="d-flex">
                                    <b className="d-block">Sección: </b>
                                    <span>{SectionHover.name}</span>
                                </div>
                                {moneda === 'USD'
                                    ? SectionHover.preciousd.trim() !== '' && (
                                        <div className="d-flex">
                                            <b className="d-block">
                                                Precio:{' '}
                                            </b>
                                            <span>
                                                desde {SectionHover.preciousd}
                                            </span>
                                        </div>
                                    )
                                    : SectionHover.preciobs.trim() !== '' && (
                                        <div className="d-flex">
                                            <b className="d-block">
                                                Precio:{' '}
                                            </b>
                                            <span>
                                                desde {SectionHover.preciobs}
                                            </span>
                                        </div>
                                    )}
                            </>
                        }
                    </div>
                )}
            </section>
        </section>
    );
};

export default Mapa;
