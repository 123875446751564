import React from 'react';

const Intro = ({ intro }) => {
    return (
        <>
            <section className="ld--intro ld--card">
                <h2 className="ld--title">{process.env.REACT_APP_TITLE}</h2>
                {intro}
            </section>
        </>
    );
};

export default Intro;
