import React from 'react';
const Map = ({ src }) => {
    return (
        <section className="ld--wrap-spotify">
            <div className="row">
                <div className="col-12 col-lg-12 d-flex flex-column mb-5">
                    <div className="ld--title">¿Como llegar?</div>
                    <div className="ld--card w-100 mb-0">
                        <iframe
                            src={src}
                            width="100%"
                            height="300"
                            className="border-radius-10"
                        ></iframe>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Map;
