import React, { useEffect, useState } from 'react';
import './map.scss';

const Mapa = ({
    onClick,
    Color = '',
    closeModal,
    host,
    entradas = null,
    moneda
}) => {
    const [Sections, setSections] = useState([]);
    const [SectionHover, setSectionHover] = useState(null);
    useEffect(() => {
        let blocks = document.querySelectorAll(
            '#EXPKANY,#DPM,#CONFIESO,#HOYYAMEVOY'
        );
        setSections(blocks);
        const scc = document.querySelector('#scroll-svg');
        console.log(scc.getBoundingClientRect().width, scc.scrollWidth);
        scc.scrollTo(
            (scc.scrollWidth - scc.getBoundingClientRect().width) / 2,
            (scc.scrollHeight - scc.getBoundingClientRect().height) / 2
        );
    }, []);
    useEffect(() => {
        if (Sections.length === 0) return;
        Array.from(Sections).map((s, i) => {
            s.addEventListener('mouseenter', hover);
            s.addEventListener('mouseleave', blur);
            if (entradas[s.id].trim().toLowerCase() === 'agotado') {
                s.style.fill = '#9e9e9e';
                s.style.cursor = 'not-allowed';
            }
            s.addEventListener('click', (e) => click(s.id));
            const childern = s.childNodes;
            if (childern) {
                childern.forEach((ch) => {
                    ch.addEventListener('click', (e) => click(s.id));
                });
            }
        });
        return () => {
            Array.from(Sections).map((s, i) => {
                s.removeEventListener('mouseenter', hover);
                s.removeEventListener('mouseleave', blur);
                s.removeEventListener('click', click);
            });
        };
    }, [Sections]);
    const hover = (e) => {
        Array.from(Sections).map((s, i) => {
            if (e.target.id === s.id) {
                s.classList.add('active');
                setSectionHover({
                    ...e.target.dataset,
                    url: entradas[e.target.id]
                });
            }
        });
    };
    const click = (section) => {
        if (
            entradas[section].trim() !== '' &&
            entradas[section].trim().toLowerCase() !== 'agotado'
        ) {
            window.location.href = `${entradas[section]}/?host=${host}`;
        }
    };
    const blur = (e) => {
        Array.from(Sections).map((s, i) => {
            s.classList.remove('active');
            setSectionHover(null);
        });
    };
    return (
        <section id="modalMap">
            <span className="overlay" onClick={() => closeModal()}></span>
            <section id="modalContent">
                <span className="cerrar" onClick={() => closeModal()}>
                    <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x "
                    >
                        <g>
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </g>
                    </svg>
                </span>
                <h3 className="text-center mb-4">Seleccionar zona</h3>
                <div id="scroll-svg">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1409 1689"
                        id="mapaV"
                    >
                        <path className="st0" d="M303.2,185h790.2c7.4,0,13.5,6,13.5,13.5v79.7c0,7.4-6,13.5-13.5,13.5H303.2c-7.4,0-13.5-6-13.5-13.5v-79.7 C289.7,191,295.8,185,303.2,185z" />
                        <path id="DPM" className="st1" d="M1106.9,496.8c0-3.6-6-6.5-13.5-6.5H303.2c-7.4,0-13.5,2.9-13.5,6.5v84.1c0,0.4,0.1,0.7,0.2,1.1 c-0.1,0.3-0.2,0.7-0.2,1.1v71c0,3.6,6,6.5,13.5,6.5h790.2c7.4,0,13.5-2.9,13.5-6.5v-71c0-0.4-0.1-0.7-0.2-1.1 c0.1-0.4,0.2-0.7,0.2-1.1L1106.9,496.8z" />
                        <path id="EXPKANY" className="st2" d="M1106.9,314.3c0-3.6-6-6.5-13.5-6.5H303.2c-7.4,0-13.5,2.9-13.5,6.5v84.1c0,0.4,0.1,0.7,0.2,1.1 c-0.1,0.4-0.2,0.7-0.2,1.1v71c0,3.6,6,6.5,13.5,6.5h790.2c7.4,0,13.5-2.9,13.5-6.5v-71c0-0.4-0.1-0.7-0.2-1.1 c0.1-0.3,0.2-0.7,0.2-1.1L1106.9,314.3z" />
                        <path id="CONFIESO" className="st3" d="M303.2,679.3h790.2c7.4,0,13.5,6,13.5,13.5v438.1c0,7.4-6,13.5-13.5,13.5H303.2 c-7.4,0-13.5-6-13.5-13.5V692.8C289.7,685.4,295.8,679.3,303.2,679.3z" />
                        <path id="HOYYAMEVOY" className="st4" d="M1109.5,1173.8v158.8c0,7.4-6,13.5-13.5,13.5h-41.2c-7.4,0-13.5,6-13.5,13.5v39.3 c0,3.6-1.4,7-4,9.6l-90.9,90.3c-2.5,2.5-5.9,3.9-9.5,3.9H300.5c-7.4,0-13.5-6-13.5-13.5v-315.3c0-7.4,6-13.5,13.5-13.5h795.5 C1103.5,1160.3,1109.5,1166.3,1109.5,1173.8z" />
                        <text transform="matrix(1 0 0 1 623.2 249.89)" className="st5 st6 st7">E</text>
                        <text transform="matrix(1 0 0 1 641.07 249.89)" className="st5 st6 st7">SCENARIO</text>
                        <text transform="matrix(1 0 0 1 612.7 404.26)" className="st8 st6 st9">EX</text>
                        <text transform="matrix(1 0 0 1 660.2 404.26)" className="st8 st6 st9 st10">P</text>
                        <text transform="matrix(1 0 0 1 682.7 404.26)" className="st8 st6 st9"> KAN</text>
                        <text transform="matrix(1 0 0 1 771.69 404.26)" className="st8 st6 st9 st10">Y</text>
                        <text transform="matrix(1 0 0 1 662.76 586.25)" className="st5 st6 st9">DPM</text>
                        <text transform="matrix(1 0 0 1 610.32 911.84)" className="st5 st6 st9">C</text>
                        <text transform="matrix(1 0 0 1 635.21 911.84)" className="st5 st6 st9">ONFI</text>
                        <text transform="matrix(1 0 0 1 724.81 911.84)" className="st5 st6 st9">E</text>
                        <text transform="matrix(1 0 0 1 747.38 911.84)" className="st5 st6 st9">SO</text>
                        <text transform="matrix(1 0 0 1 564.82 1331.4)" className="st5 st6 st9">H</text>
                        <text transform="matrix(1 0 0 1 593.21 1331.4)" className="st5 st6 st9 st10">O</text>
                        <text transform="matrix(1 0 0 1 619.58 1331.4)" className="st5 st6 st9 st10">Y</text>
                        <text transform="matrix(1 0 0 1 642.8 1331.4)" className="st5 st6 st9 st10"> </text>
                        <text transform="matrix(1 0 0 1 650.28 1331.4)" className="st5 st6 st9 st11">Y</text>
                        <text transform="matrix(1 0 0 1 672.36 1331.4)" className="st5 st6 st9 st10">A</text>
                        <text transform="matrix(1 0 0 1 696.76 1331.4)" className="st5 st6 st9"> ME</text>
                        <text transform="matrix(1 0 0 1 761.13 1331.4)" className="st5 st6 st9 st10"> </text>
                        <text transform="matrix(1 0 0 1 768.69 1331.4)" className="st5 st6 st9 st10">V</text>
                        <text transform="matrix(1 0 0 1 793.2 1331.4)" className="st5 st6 st9 st10">O</text>
                        <text transform="matrix(1 0 0 1 819.57 1331.4)" className="st5 st6 st9 st10">Y</text>
                        <path className="st12" d="M273.6,162h861.9c23.1,0,41.9,18.8,41.9,41.9v1281.3c0,23.1-18.8,41.9-41.9,41.9H273.6 c-23.1,0-41.9-18.8-41.9-41.9V203.9C231.7,180.7,250.4,162,273.6,162z" />
                    </svg>
                </div>
                {SectionHover && false && (
                    <div className="infoSec">
                        {
                            <>
                                {SectionHover.url.trim().toLowerCase() ===
                                'agotado' && (
                                    <div className="d-flex">
                                        <b className="d-block">AGOTADO</b>
                                    </div>
                                )}
                                <div className="d-flex">
                                    <b className="d-block">Sección: </b>
                                    <span>{SectionHover.name}</span>
                                </div>
                                {moneda === 'USD'
                                    ? SectionHover.preciousd.trim() !== '' && (
                                        <div className="d-flex">
                                            <b className="d-block">
                                                Precio:{' '}
                                            </b>
                                            <span>
                                                desde {SectionHover.preciousd}
                                            </span>
                                        </div>
                                    )
                                    : SectionHover.preciobs.trim() !== '' && (
                                        <div className="d-flex">
                                            <b className="d-block">
                                                Precio:{' '}
                                            </b>
                                            <span>
                                                desde {SectionHover.preciobs}
                                            </span>
                                        </div>
                                    )}
                            </>
                        }
                    </div>
                )}
            </section>
        </section>
    );
};

export default Mapa;
